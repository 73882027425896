import bodyReg from './UniNeueRegular.woff2';
import bodyLight from './UniNeueLight.woff2';
import bodyBold from './UniNeueBold.woff2';
import bodyBoldItalic from './UniNeueBold-Italic.woff2';

import { css } from 'linaria';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
    @font-face {
      font-family: 'unineue';
      src: url(${bodyReg}) format('woff2');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'unineue';
      src: url(${bodyLight}) format('woff2');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'unineue';
      src: url(${bodyBold}) format('woff2');
      font-weight: 700;
      font-style: normal;
      font-display: swap;
    }

    /* header font */
    @font-face {
      font-family: 'unineue';
      src: url(${bodyBoldItalic}) format('woff2');
      font-weight: 700;
      font-style: italic;
      font-display: swap;
    }
  `;
}
