import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import { ContentHtml, contentTextElements } from '../ContentText';
import MaxWidth from '../../Layout/MaxWidth';
import { isHexCode } from '../../Layout/Content/CampaignBar';

const StyledMax = styled(MaxWidth)`
  margin-top: 2%;
  margin-bottom: 2%;
  ${theme.below.md} {
    padding: 0;
  }
`;

const Wrapper = styled.div``;

const TextBoxWrapper = styled('div')`
  margin: 0 auto 0;
  width: 100%;
  .content-html-text {
    width: 100%;
    margin: 0 auto;
    padding-left: ${theme.general.pagePadding.sm};
    padding-right: ${theme.general.pagePadding.sm};
    ${contentTextElements} {
      color: inherit;
    }
    &.center {
      > ul {
        display: flex;
        width: 100%;
        margin: 0 auto;
        justify-content: space-around;
        flex-wrap: wrap;
        > li {
          margin: 15px;
          display: flex;
          align-items: center;
          img {
            margin-right: 15px;
          }
        }
      }
    }
  }
  padding: 2rem 0;
`;

const TextBox = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const text = props.text.value;
  const textAlign = props.textAlign.value;
  const textColor = props.textColor?.value;
  const background = props.background?.value;
  const maxWidth = props.maxWidth && props.maxWidth.value;
  const margins = props.margins?.value === 'yes';
  const Tag = margins ? StyledMax : Wrapper;

  const boxBackground = isHexCode(background, 'transparent');
  const boxColor = isHexCode(textColor, theme.colors.font);

  return (
    <Tag>
      <TextBoxWrapper
        data-index={index}
        style={{ background: boxBackground, color: boxColor }}
      >
        <ContentHtml
          style={{ maxWidth: maxWidth }}
          className={`content-html-text ${textColor} ${textAlign}`}
          content={text}
        />
      </TextBoxWrapper>
    </Tag>
  );
};

export default TextBox;
