import React from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import t from '@jetshop/intl';
import { VariantHeading } from './VariantSelector/VariantButtons';

const BrandWrapper = styled('div')`
  margin: 1.5rem 0 0 0;
  h5 {
    text-transform: uppercase;
    color: #aaa;
    letter-spacing: 2.5px !important;
  }
  > * {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    p {
      margin-left: 5px;
    }
  }
`;

export const Brand = ({ product }) => {
  if (!product) return null;

  const subName = product.subName;

  //BRAND CATEGORY
  const brandCategory = product.categories?.filter(
    (cat) => cat.name.toLowerCase() === product.subName.toLowerCase()
  )[0];

  return (
    <BrandWrapper>
      <>
        {brandCategory ? (
          <CategoryLink category={brandCategory}>
            <VariantHeading>{t('Brand')}</VariantHeading>
            <p>{subName}</p>
          </CategoryLink>
        ) : (
          <div>
            <VariantHeading>{t('Brand')}</VariantHeading>
            <p>{subName}</p>
          </div>
        )}
      </>
    </BrandWrapper>
  );
};
