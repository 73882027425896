import React from 'react';
import { styled } from 'linaria/react';
import { RowProducts } from '../../ui/ProductRow/RowProducts';
import { ProductListSubHeader } from '../StyledComponents';

const RelatedWrapper = styled('div')`
  margin: 2rem 0 1rem 0;
  width: 100%;
  > h3 {
    margin-bottom: 1rem;
  }
  .related-product-grid {
  }
`;

const RelatedProducts = ({ products, title, className, ...rest }) => {
  const category = {
    name: 'Related products',
    products: {
      result: products,
    },
  };

  return (
    <RelatedWrapper id="related-products">
      <ProductListSubHeader>{title}</ProductListSubHeader>
      <RowProducts
        category={category}
        slider={true}
        quickBuy={true}
        className={'addon'}
        {...rest}
      />
    </RelatedWrapper>
  );
};

export default RelatedProducts;
