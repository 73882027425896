import React from 'react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { theme } from '../Theming/Theming';

const CampaignWrapper = styled('div')`
  margin-top: 0.5rem;
  padding: 0;
  ul {
    margin-top: 0.5rem;
  }
`;

const CampaignLink = styled(CategoryLink)`
  text-decoration: none;
  color: ${theme.colors.buy};
`;

export function Campaigns({ campaigns, className, ...rest }) {
  if (!campaigns || campaigns.length === 0) {
    return null;
  }

  return (
    <CampaignWrapper {...rest}>
      <h3>{t('Included in campaign:')}</h3>
      <ul>
        {campaigns.map((campaign) => {
          return (
            <li key={campaign.id}>
              <h4>
                {campaign.primaryRoute ? (
                  <CampaignLink category={campaign}>
                    {campaign.name}
                  </CampaignLink>
                ) : (
                  <>{campaign.name}</>
                )}
              </h4>
            </li>
          );
        })}
      </ul>
    </CampaignWrapper>
  );
}
