import React from 'react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';
import t from '@jetshop/intl';
import { VariantHeading } from './VariantSelector/VariantButtons';

const CategoriesWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  > ul {
    display: flex;
    flex-wrap: wrap;
    > li:not(:last-child) {
      margin-right: 0.5rem;
      h4:after {
        content: ',';
        margin: 0 2px;
      }
    }
  }
`;

const StyledLink = styled(CategoryLink)`
  text-decoration: none;
  color: ${theme.colors.buy};
`;

export const Categories = ({ categories, className, ...rest }) => {
  if (!categories || categories.length === 0) {
    return null;
  }

  return (
    <CategoriesWrapper className={className} {...rest}>
      <VariantHeading>{t('Categories')}</VariantHeading>
      <ul>
        {categories.map((category) => {
          return (
            <li key={category.id}>
              <h4>
                <StyledLink category={category}>{category.name}</StyledLink>
              </h4>
            </li>
          );
        })}
      </ul>
    </CategoriesWrapper>
  );
};
