import React from 'react';
import { default as Breadcrumbs } from '@jetshop/ui/Breadcrumbs';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';

const BreadcrumbsStyles = styled('div')`
  padding: 1rem 0 1rem;
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    > li {
      font-size: 0.9rem !important;
      a {
        font-size: 0.9rem !important;
        color: ${theme.colors.greys[3]};
      }
      &:not(:last-child):after {
        display: inline-block;
        padding: 0 0.66em;
        color: ${theme.colors.greys[3]};
      }
      &:last-child {
        color: ${theme.colors.primary};
        &:after {
          content: '';
        }
      }
    }
  }
`;

export const BreadcrumbsWrapper = ({ ...props }) => (
  <BreadcrumbsStyles className="breadcrumbs-wrapper">
    <Breadcrumbs {...props} />
  </BreadcrumbsStyles>
);
