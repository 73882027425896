import React from 'react';
import parse, { domToReact } from 'html-react-parser';
import CheckIcon from '../ui/icons/CheckIcon';
import { domLink } from '../ui/TextContent';

export const SiteContentHtmlParser = ({ content }) => {
  if (!content) return null;
  const options = {
    replace: (domNode) => {
      if (domNode && !domNode.attribs) return;
      if (
        domNode.name === 'li' &&
        domNode.attribs.class &&
        domNode.attribs.class.search('icon') !== -1
      ) {
        return (
          <li className={domNode.attribs.class}>
            <CheckIcon />
            {domToReact(domNode.children, options)}
          </li>
        );
      }
      return domLink({ domNode, options });
    },
  };
  return parse(content, options);
};
