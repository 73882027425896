import React from 'react';
import { styled } from 'linaria/react';
import { withRouter } from 'react-router-dom';
import { ProductInfoMarginBottom } from '../StyledComponents';
import { SiblingButtons } from './SiblingButtons';
import { useSiblingProducts } from './useSiblingProducts';

const Wrapper = styled('div')`
  width: 100%;
  &:not(.grid) {
    ${ProductInfoMarginBottom};
  }
`;

const SiblingProducts = ({
  product,
  grid,
  setImage,
  setProduct,
  id,
  inView = true,
}) => {
  const productList = useSiblingProducts({ product, id, inView });
  if (!productList || productList.length === 0) return null;

  return (
    <Wrapper className={`${grid ? 'grid' : ''} sibling-products`}>
      <SiblingButtons
        productList={productList}
        mainProduct={product}
        grid={grid}
        setImage={setImage}
        setProduct={setProduct}
      />
    </Wrapper>
  );
};

export default withRouter(SiblingProducts);
