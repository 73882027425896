import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import cartQuery from '../../Cart/queries/cartQuery.gql';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../../Theming/Theming';
import React, { useState } from 'react';
import { styled } from 'linaria/react';
import useProductToast from './useProductToast';
import ButtonWithLoading from '../../ui/Button';
import Popup from '../../ui/Popup';
import FreeShipping from '../../Cart/FreeShipping';
import { Price } from '../../Price';
import RelatedProducts from '../RelatedProducts/RelatedProducts';
import ProductRow from '../../ui/ProductRow/ProductRow';
import { useContext } from 'react';
import SiteContentContext from '../../SiteContent/SiteContentContext';

const Container = styled('aside')`
  background-color: ${theme.colors.white};
  line-height: 1em;
`;

const CheckoutButton = styled(ButtonWithLoading)`
  background: ${theme.colors.primaryDark};
`;

const ProductImageWrapper = styled('div')`
  width: 5rem;
  margin-right: 1rem;
`;

const ProductCheckoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .product-row-outer-wrapper {
    margin: 2rem 0;
  }
`;

const Product = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px;
`;

const ProductDetails = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: black;
`;

const Header = styled('h3')`
  margin-bottom: 0.5rem;
`;

const ProductName = styled('p')`
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`;

const VariantText = styled('span')`
  &:before {
    content: '-';
    margin: 0 3px;
  }
`;

const Buttons = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  >*{
    width: 48%;
    padding: 0;
    font-size: 1rem;
    text-transform: uppercase;
    a{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:focus{
        color:inherit;
      }
    }
}
  .secondary {
    background: white;
    color: ${theme.colors.font}
    border: 1px solid ${theme.colors.border};
  }
`;

const Error = styled('p')`
  color: red;
  margin-top: 10px;
`;

const ProductPopupComp = ({
  product,
  cart,
  selectedVariation,
  quantity,
  error,
}) => {
  const selected = selectedVariation || product;
  const { price, previousPrice, image } = useProductToast({
    product,
    selectedVariation,
    quantity,
  });
  const track = useTracker();
  const [show, setShow] = useState(true);
  const { addonPopupTitle } = useContext(SiteContentContext);

  return (
    <Popup
      isOpen={show}
      closePopup={() => setShow(false)}
      className="cart-popup"
    >
      <Container>
        <ProductCheckoutContainer>
          <Product>
            {product.images.length > 0 && (
              <ProductImageWrapper>
                <Image
                  src={image.url}
                  sizes={100}
                  aspect="1:1"
                  alt={image.alt}
                />
              </ProductImageWrapper>
            )}
            <ProductDetails>
              {error ? (
                <Header>{t('Failed adding to bag')}</Header>
              ) : (
                <Header>{t('Added to bag')}</Header>
              )}
              <ProductName>
                {product.name}
                {selected.values && selected.values.length > 0 && (
                  <VariantText>{selected.values[0]}</VariantText>
                )}
                {selected.values && selected.values.length > 1 && (
                  <VariantText>{selected.values[1]}</VariantText>
                )}
              </ProductName>

              {!error && <Price price={price} previousPrice={previousPrice} />}
              {error && (
                <Error>
                  {t('An error occurred. Details:')}
                  <ul>
                    {error.graphQLErrors && error.graphQLErrors.length > 0 ? (
                      error.graphQLErrors.map(
                        ({ message, locations, path }) => (
                          <li key={message}>{t(message)}</li>
                        )
                      )
                    ) : (
                      <li>{t(error.message)}</li>
                    )}
                  </ul>
                </Error>
              )}
            </ProductDetails>
          </Product>
          {cart?.productTotal && <FreeShipping cartTotal={cart.productTotal} />}
          {product.relatedProducts && product.relatedProducts.length > 0 ? (
            <RelatedProducts
              products={product.relatedProducts}
              onClick={() => setShow(false)}
              className="addon"
              title={addonPopupTitle}
            />
          ) : (
            <ProductRow
              id={1552}
              slider={true}
              quickBuy={true}
              className="addon"
              onClick={() => setShow(false)}
              subTitle={addonPopupTitle}
            />
          )}

          <Buttons>
            <ButtonWithLoading
              className="secondary"
              onClick={() => setShow(false)}
            >
              {t('Continue shopping')}
            </ButtonWithLoading>
            {cart && cart.externalCheckoutUrl && (
              <CheckoutButton>
                <a
                  href={cart.externalCheckoutUrl}
                  onClick={(event) => {
                    event.preventDefault();
                    track(
                      trackCartCheckoutEvent({
                        cart: cart,
                        callback: () => {
                          window.location = cart.externalCheckoutUrl;
                        },
                      })
                    );
                  }}
                >
                  {t('To checkout')}
                </a>
              </CheckoutButton>
            )}
          </Buttons>
        </ProductCheckoutContainer>
      </Container>
    </Popup>
  );
};
const ProductPopup = (props) => (
  <CartProvider query={cartQuery}>
    {({ data }) => <ProductPopupComp {...props} cart={data && data.cart} />}
  </CartProvider>
);

export default ProductPopup;
