import parse from 'html-react-parser';
import { domLink } from '../ui/TextContent';

const getShortDescription = ({ product }) => {
  let relatedColor = false;
  const options = {
    replace: (domNode) => {
      if (domNode && !domNode.attribs) return;
      if (domNode.attribs.id === 'related-color') {
        relatedColor = domNode.children.length && domNode.children[0].data;
        return null;
      }
      return domLink({ domNode, options });
    },
  };

  const shortdescr = product?.shortDescription
    ? parse(product.shortDescription, options)
    : false;

  return { relatedColor, shortdescr };
};

export default getShortDescription;
