import { useState } from 'react';
import upsellProductQuery from './UpsellProductQuery.gql';
import { useQuery } from 'react-apollo';
import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import addMultipleToCartMutation from '../../../Cart/queries/addMultipleToCart.gql';
import cartQuery from '../../../Cart/queries/cartQuery.gql';

const useUpsellProducts = ({ product }) => {
  //LIST WITH ADDED UPSELL PRODUCTS
  const [purchaseList, setList] = useState([]);

  //FIX ITEMS FOR ADDING TO CART
  const cartItems = getItemsForAddToCart(purchaseList);

  //ADD MULTIPLE TO CART
  const [addToCart] = useAddMultipleToCart(cartItems, {
    addMultipleToCartMutation,
    cartQuery,
  });

  //QUERY UPSELL PRODUCTS
  const result = useQuery(upsellProductQuery, {
    variables: {
      articleNumber: product.articleNumber,
    },
  });

  const mainProduct = result?.data?.product;
  const upsellProducts = mainProduct?.upsell?.items;

  //CALC PRICE PERCENTAGE OR VALUE
  const items = upsellProducts?.map((item) => {
    const { product, discount } = item;
    const upsellDiscount = {
      exVat:
        (discount.percentage && discount.percentage * product.price.exVat) ||
        discount.valueExVat,
      incVat:
        (discount.percentage && discount.percentage * product.price.incVat) ||
        discount.valueIncVat,
      vat:
        (discount.percentage && discount.percentage * product.price.vat) ||
        discount.valueVat,
    };
    return {
      ...item,
      upsellPrice: {
        previousPrice: product.price,
        price: {
          exVat: product.price.exVat - upsellDiscount.exVat,
          incVat: product.price.incVat - upsellDiscount.incVat,
          vat: product.price.vat - upsellDiscount.vat,
        },
      },
      upsellDiscount,
    };
  });

  const addItemsToCart = () => {
    if (purchaseList.length > 0) {
      addToCart();
      setList([]);
    }
  };

  function getItemsForAddToCart(items) {
    // When adding to cart we only want valid items, and each articleNumber needs
    // to be the variant rather than the base product
    return items.map((item) => ({
      articleNumber: item.variant
        ? item.variant.articleNumber
        : item.product.articleNumber,
      quantity: item.minQuantity,
    }));
  }

  //UPDATE LIST
  const add = (item) => {
    if (selected(item)) setList([...getOthers(item), item]);
    else setList([...purchaseList, item]);
  };
  const remove = (item) => {
    setList(
      purchaseList.filter(
        (purchaseItem) => purchaseItem.product.id !== item.product.id
      )
    );
  };
  const selected = (item) => {
    return purchaseList.filter(
      (purchaseItem) => purchaseItem.product.id === item.product.id
    )[0];
  };
  const getOthers = (item) => {
    return purchaseList.filter(
      (purchaseItem) => purchaseItem.product.id !== item.product.id
    );
  };

  return {
    items,
    addItemsToCart,
    purchaseList,
    update: {
      add,
      remove,
      selected,
    },
  };
};

export default useUpsellProducts;
