import { useQuery } from 'react-apollo';
import { filterList } from './helpers';
import siblingSearchQuery from './siblingSearchQuery.gql';

export const useSiblingProducts = ({ id, inView }) => {
  //GET SEARCH TERM
  const term = id;

  //QUERY
  const result = useQuery(siblingSearchQuery, {
    variables: { term: term },
    errorPolicy: 'all',
    skip: !term || !inView,
  });

  const productList = result.data?.search?.products?.result;
  if (productList && productList.length > 1)
    return filterList(productList, term);
  return null;
};
