import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { UpsellProduct } from './UpsellProduct';
import useUpsellProducts from './hook/useUpsellProducts';

const UpsellWrapper = styled('div')`
  margin: 1rem 0 1rem 0;
  width: 100%;
  > h3 {
    margin: 1rem 0;
  }
  .product-grid {
    display: flex;
    flex-wrap: wrap;
    > * {
      width: 100%;
    }
    ${theme.above.lg} {
      padding: 0 -1rem;
      > * {
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
`;

const UpsellProducts = ({ product, addToCartTrigger }) => {
  const { items, addItemsToCart, update } = useUpsellProducts({
    product
  });

  //ADD TO CART WHEN MAIN PRODUCT IS PURCHASED
  useEffect(() => {
    if (addToCartTrigger) {
      addItemsToCart();
    }
  });

  if (!items || items.length === 0) return null;

  return (
    <UpsellWrapper>
      {items.map((item, index) => {
        return <UpsellProduct key={index} item={item} update={update} />;
      })}
    </UpsellWrapper>
  );
};

export default UpsellProducts;
