import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import SiteContentContext from '../../../SiteContent/SiteContentContext';
import { theme } from '../../../Theming/Theming';

const TopBarUspWrapper = styled('div')`

    ${theme.only.lg} {
        padding-right: 80px;
    }
    
    
    > ul {
        > li {
            display: flex;
            align-items: center;
            font-size: ${theme.topBar.usp.fontSize};
            font-weight: ${theme.topBar.usp.fontWeight} !important;

            ${theme.only.xs} {
                font-size: 0.85rem;
            }

            svg {
                margin-right: 5px;
            }

            //Overrides Testfreaks !important styles
            &:first-child {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            &:nth-of-type(4):is(li, #A#A#A:not(*)) {
                margin-left: unset !important;
            }

            ${theme.below.sm} {
                &:first-child {
                    padding-left: 10vw !important;
                    padding-right: 0 !important;
                }
                
                &:nth-of-type(2),
                &:nth-of-type(3) {
                    display: none;
                }
            }

            ${theme.below.md} {
                &:nth-of-type(3) {
                    display: none;
                }
            }

            &:last-child {
                order: -1;
            }
        }
    }
`;

const TopBarUsp = ({ data }) => {
  const { topBarUsp } = useContext(SiteContentContext);
  const outputData = data ? data : topBarUsp;
  if (outputData) {
    return (
      <TopBarUspWrapper className="top-bar-usp-list">
        {outputData}
      </TopBarUspWrapper>
    );
  }
  return null;
};

export default TopBarUsp;
