import { theme } from '../Theming/Theming';

export const HallmarkBorder = `
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
  font-weight: ${theme.weights.bold};
  &:after {
    margin: 1rem auto 0;
    content: ' ';
    display: block;
    border-bottom: ${theme.general.hallmarkBorder};
    max-width: 50px;
  }
`;

//FULL WIDTH CONTAINER INSIDE LIMITED WIDTH
export const fullWidth = `
  @media (min-width: ${theme.general.maxWidth}) {
    margin-left: calc(-100vw / 2 + ${theme.general.maxWidth} / 2);
    margin-right: calc(-100vw / 2 + ${theme.general.maxWidth} / 2);
  }
`;

//CUSTOM SCROLL BAR
export const scrollBar = `
  overflow: auto;
  padding-right: 5px;
  ::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f9f9f9;
  }

  ::-webkit-scrollbar-thumb {
    background: #eee;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #ddd;
  }
`;

export const imageOverlay = `
  display: block;
  position:relative;
  padding: 10px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.04;
  }
`;

export function scrollTo(element, click = false) {
  const tabs = document.getElementById(element);
  if (tabs) {
    if (click) tabs.click();
    const yOffset = -100;
    const y = tabs.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}

export const scrollToTarget = ({ target, duration = 800, offset = 0 }) => {
  const top = target.getBoundingClientRect().top;
  const startPos = window.pageYOffset;
  const diff = top + offset;

  let startTime = null;
  let requestId;

  const loop = function (currentTime) {
    if (!startTime) {
      startTime = currentTime;
    }

    // Elapsed time in miliseconds
    const time = currentTime - startTime;

    const percent = Math.min(time / duration, 1);
    window.scrollTo(0, startPos + diff * percent);

    if (time < duration) {
      // Continue moving
      requestId = window.requestAnimationFrame(loop);
    } else {
      window.cancelAnimationFrame(requestId);
    }
  };
  requestId = window.requestAnimationFrame(loop);
};
