import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import SiteContentContext from '../../../SiteContent/SiteContentContext';
import { FavouriteCount } from '../../../ProductList/FavouriteCount';

const TopBarLinksWrapper = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  > ul {
    > li {
      text-decoration: underline;
    }
  }
  .fav-top-bar {
    position: relative;
    display: flex;
    align-items: center;
    p,
    svg {
      color: white;
    }
    p {
      margin-left: 5px;
      text-decoration: underline;
    }
    .number-badge {
      left: 5px;
      right: auto;
      top: 6px;
      width: 12px;
      height: 12px;
      font-size: 0.5rem;
    }
  }
`;

const TopBarLinks = () => {
  const { topBarLinks } = useContext(SiteContentContext);
  return (
    <TopBarLinksWrapper className="top-bar-links-list">
      {topBarLinks}
      <FavouriteCount title={true} className="fav-top-bar" />
    </TopBarLinksWrapper>
  );
};

export default TopBarLinks;
