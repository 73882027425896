export const config = {
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/favoriter',
    },
    blog: {
      path: process.env.REACT_APP_BLOG_PATH || '/blogg',
      id: process.env.REACT_APP_BLOG_ID || 218,
      mainLevel: 1,
      menu: true,
    },
    brand: {
      id: 204,
    },
    category: {
      hide: false,
    },
    product: {
      hide: false,
    },
  },
  settings: {
    storeName: 'Minfot',
    channels: {
      1: {
        domain: 'minfot.se',
        name: 'Minfot',
      },
      2: {
        domain: 'myfeet.no',
        name: 'Myfeet',
      },
      3: {
        domain: 'minfot.dk',
        name: 'Minfot',
      },
      4: {
        domain: 'minfot.se',
        name: 'Flawless walk',
      },
    },
    siteContentId: 139,
  },
};

export function getChannel(id) {
  if (config.settings.channels[id]) return config.settings.channels[id];
  return config.settings.channels[1];
}
