import ProductConfigurationContext from '@jetshop/core/components/ProductConfigurationProvider/ProductConfigurationContext';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import { theme } from '../Theming/Theming';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { GridPrice as Price } from '../Price';
import { Price as UIPrice } from '@jetshop/ui/Price';
import { useDynamicPrice } from '@jetshop/core/hooks/useDynamicPrice';
import NotifyWhenBack from './StockStatus/NotifyWhenBack';
import StockStatusIndicator from './StockStatus/StockStatusIndicator';
import { VariantSelector } from './VariantSelector/VariantSelector';
import InputWithLabel from '../Forms/Input';
import AddToCartForm from './AddToCart/AddToCartForm';
import { Form } from 'formik';
import AddToCartWrapper from './AddToCart/AddToCartWrapper';
import { ProductInfoMarginBottom } from './StyledComponents';
import { useProductValidationMessage } from './helpers';
import loadable from '@loadable/component';
import Spinner from '../ui/Spinner';
import ProductUspBox from './ProductUspBox';
import { Favourite } from '../ProductList/Favourite';
import { Campaigns } from './Campaigns';
import { Categories } from './Categories';
import { BreadcrumbsWrapper } from '../Layout/BreadcrumbsWrapper';
import WarehouseStock from './StockStatus/WarehouseStock';
import { getFontSize } from '../Theming/helpers';
import UpsellProducts from './UpsellProduct/UpsellProducts';
import getShortDescription from './ShortDescription';
import SiblingProducts from './SiblingProducts/SiblingProducts';
import Highlights from './Highlights';
import Discount from './Discount';
import { PriceHistoryWrapper } from './Price/PriceHistory';
import SiteContentContext from '../SiteContent/SiteContentContext';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { Brand } from './Brand';

const LoadablePackageProduct = loadable(
  () => import('./PackageProduct/PackageProduct'),
  {
    fallback: <Spinner />,
  }
);

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  ${theme.above.md} {
    align-items: flex-start;
  }
`;

export const Header = styled('header')`
  width: 100%;
  ${ProductInfoMarginBottom};
  .breadcrumbs-wrapper {
    padding: 0.5rem 0;
  }
  #testfreaks-badge {
    min-height: 24px;
  }
`;

export const ProductHeading = styled('h1')`
  display: flex;
  justify-content: space-between;
  font-weight: ${theme.productGrid.product.name.fontWeight} !important;
  ${getFontSize(theme.typography.header.fontSize[2])}
`;

/*const ArticleNumber = styled('div')`
  ${ProductInfoMarginBottom}
  .article-number-label {
    font-weight: ${theme.weights.normal};
  }
  .article-number-value {
    margin-left: 5px;
  }
`;*/

export const PriceWrapper = styled('div')`
  display: flex;
  align-items: baseline;
  [data-flight-price] {
    ${UIPrice.Normal} {
      ${getFontSize(theme.productPage.price.normal.fontSize)}
    }
    ${UIPrice.New} {
      ${getFontSize(theme.productPage.price.new.fontSize)}
      margin-bottom: 0 !important;
    }
    ${UIPrice.Old} {
      ${getFontSize(theme.productPage.price.old.fontSize)}
    }
  }
  .price-history-wrapper {
    margin-left: 10px;
  }
`;

const StockStatusWrapper = styled('div')`
  margin-bottom: 0.5rem;
  .stock-wrapper {
    margin-bottom: 0;
  }
  .stock-label {
    font-weight: ${theme.weights.normal};
    text-transform: none;
  }
  .free-freight-stock {
    display: inline-block;
    margin: 0 0 0 0.8rem;
  }
`;

const VariantsWrapper = styled('div')`
  width: 100%;
  > div {
    ${ProductInfoMarginBottom}
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const ShortDescriptionWrapper = styled('article')`
  line-height: ${theme.typography.paragraph.lineHeight};
  ${theme.below.md} {
    font-size: 0.9rem;
  }
  p {
    margin: 0 0 0.5rem 0;
  }
`;

const ProductNameWrapper = styled.span`
  text-transform: none;
  .brand {
    font-weight: ${theme.weights.bold};
  }
`;

export const ProductName = ({ name, brand, brandCategory = false }) => {
  const rest = name?.replace(brand, '').trim();
  if (brand && rest)
    return (
      <ProductNameWrapper>
        {brandCategory ? (
          <CategoryLink category={brandCategory}>
            <span className="brand">{brand}</span>
          </CategoryLink>
        ) : (
          <span className="brand">{brand}</span>
        )}
        <span className="name"> {rest}</span>
      </ProductNameWrapper>
    );
  return name;
};

export const ShortDescription = ({ product }) => {
  //DESCRIPTION Short
  const shortdescr =
    product.shortDescription &&
    product.shortDescription.split('\n').reduce((merged, current, index) => {
      merged.push(<p key={index}>{current}</p>);
      return merged;
    }, []);
  return <ShortDescriptionWrapper>{shortdescr}</ShortDescriptionWrapper>;
};

const Details = ({ product, variantHandler, breadcrumbProps, customData }) => {
  const {
    selectedVariant: selectedVariation,
    validation: variantValidation,
    getMissingOptions,
    missingOptions,
  } = variantHandler;

  const selected = selectedVariation || product;
  const configurationContext = useContext(ProductConfigurationContext);
  const stockStatus = useStockStatus(selectedVariation || product);
  const price = useDynamicPrice(product, selectedVariation);
  const outOfStockNotify = stockStatus.status === 'notifyWhenBack';

  const { hasVariants, hasUpsell, hidePrice } = product;
  const productValidation = useProductValidationMessage({
    missingOptions,
    variantValidation,
    hasVariants,
    stockStatus,
  });

  const completeProduct = product.stockStatus?.text;

  const { relatedColor, shortDescr } = getShortDescription({ product });
  const { sizeGuideTitle } = useContext(SiteContentContext);

  const hasDiscount = product?.badges?.filter(
    (item) => item.name === 'Mängdrabatt'
  )[0];
  const hasSizeGuide = product.customFields?.filter(
    (item) => item.key === 'tab-page-sizeguidespecific'
  )[0];

  return (
    <Wrapper>
      <Header>
        <div style={{ minHeight: '2.4rem', width: '100%' }}>
          <BreadcrumbsWrapper {...breadcrumbProps} />
        </div>
        <ProductHeading data-testid="product-title">
          <ProductName name={product.name} brand={product.subName} />
          <Favourite product={product} variant={selectedVariation} />
        </ProductHeading>
        <div
          id="testfreaks-badge"
          onClick={() => {
            //SCROLL TO TABS
            if (typeof document !== 'undefined') {
              const tabs = document.getElementById('tab-header-reviews');
              if (tabs) {
                const yOffset = -100;
                const y =
                  tabs.getBoundingClientRect().top +
                  window.pageYOffset +
                  yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
                tabs.click();
              }
            }
          }}
        ></div>
      </Header>
      <PriceWrapper>
        <Price
          {...price}
          hidePrice={product.hidePrice}
          css={{
            opacity:
              configurationContext && configurationContext.loading ? 0.3 : 1,
          }}
        />
        {!hidePrice && (
          <PriceHistoryWrapper
            articleNumber={product.articleNumber}
            variant={selectedVariation}
          />
        )}
      </PriceWrapper>
      <ShortDescriptionWrapper>{shortDescr}</ShortDescriptionWrapper>
      <SiblingProducts product={product} id={relatedColor} />
      {product?.isPackage ? (
        <LoadablePackageProduct product={product} />
      ) : (
        <AddToCartForm
          product={product}
          variant={selectedVariation}
          getMissingOptions={getMissingOptions}
        >
          {(addToCartTrigger) => {
            return (
              <>
                {product.hasVariants && (
                  <VariantsWrapper>
                    <VariantSelector
                      product={product}
                      variantHandler={variantHandler}
                      showValidation={!!productValidation.validationMessage}
                      sizeGuideTitle={hasSizeGuide ? sizeGuideTitle : false}
                    />
                  </VariantsWrapper>
                )}

                {completeProduct && (
                  <StockStatusWrapper>
                    <StockStatusIndicator
                      status={stockStatus.status}
                      text={stockStatus.text}
                    />
                  </StockStatusWrapper>
                )}
                {hasUpsell && (
                  <UpsellProducts
                    product={product}
                    addToCartTrigger={addToCartTrigger}
                  />
                )}
                <StyledForm>
                  {product.customerComments &&
                    product.customerComments.map((comment) => (
                      <InputWithLabel
                        wrapperClassName="customer-comment-input-wrapper"
                        className="customer-comment-input"
                        data-testid={`${comment.name}-input`}
                        id={`comments['${comment.name}']`}
                        name={`comments['${comment.name}']`}
                        label={comment.name}
                        key={comment.name}
                      />
                    ))}
                  {!outOfStockNotify && completeProduct && (
                    <AddToCartWrapper
                      variantValidation={variantValidation}
                      productValidation={productValidation}
                      product={product}
                    />
                  )}
                </StyledForm>
                {outOfStockNotify && (
                  <NotifyWhenBack articleNumber={selected.articleNumber} />
                )}
                {(product?.warehouseStock?.length > 0 ||
                  product?.variants?.values[0]?.warehouseStock?.length > 0) && (
                  <WarehouseStock
                    product={product}
                    variantHandler={variantHandler}
                  />
                )}
              </>
            );
          }}
        </AddToCartForm>
      )}
      <ProductUspBox />
      <Highlights data={customData} />
      {hasDiscount && <Discount product={product} />}
      <Brand product={product} />
      <Categories categories={product.categories} />
      <Campaigns campaigns={product.campaigns} />
    </Wrapper>
  );
};

export default Details;
