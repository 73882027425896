import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';
import PageContent from '../Util/PageContent';
import TextContent from '../ui/TextContent';

const DiscountWrapper = styled('div')`
  margin-top: 1rem;
  background: ${theme.colors.primary};
  width: 100%;
  h2,
  h3,
  h4 {
    margin: 0;
  }
  > div {
    overflow: hidden;
    display: flex;
    width: 100%;
    > div {
      width: 50%;
    }
  }
  .left {
    color: white;
    padding: 10px 20px 5px 20px;
    h2 {
      line-height: 1.1;
    }
  }
  .right {
    > ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      list-style-type: none;
      > li {
        width: 50%;
        padding: 10px 10px;
        border-bottom: 1px solid white;
        border-right: 1px solid white;
        background: #ddd;
        color: #654ea3;
        text-align: center;
        h3,
        h4 {
          text-transform: uppercase;
        }
        h3 {
          font-weight: ${theme.weights.bold};
        }
      }
    }
  }
  .left {
  }
  p {
    line-height: 1.1;
  }
`;

const Discount = () => {
  return (
    <DiscountWrapper>
      <PageContent pageId={111}>
        {(page) => <TextContent content={page?.content} />}
      </PageContent>
    </DiscountWrapper>
  );
};

export default Discount;
