import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { ProductInfoMarginBottom } from '../StyledComponents';
import { scrollTo } from '../../ui/General';

const StyledButton = styled('button')`
  background: transparent;
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: center;
  padding: 0.8rem;
  font-size: 0.9rem;
  width: 100%;
  ${theme.above.md} {
  }
  &[data-selected='true'] {
    border-color: ${theme.colors.font};
    font-weight: ${theme.weights.bold};
  }
  &[data-disabled='true'] {
    cursor: not-allowed;
  }
  &.not-buyable,
  &[data-disabled='true'] {
    border-color: #eee;
    background: ${theme.colors.greys[0]};
    color: #aaa;
    text-decoration: line-through;
    &[data-selected='true'] {
      border-color: #777;
    }
  }
  &.color {
    padding: 0;
    .color-circle {
      > div {
        width: 36px;
        height: 36px;
        border-radius: 0;
        border: none;
      }
    }
  }
`;
const VariantButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -0.5% 0;
  ${ProductInfoMarginBottom}
  &.invalid button {
    box-shadow: 0 0 10px rgba(195, 45, 45, 0.38);
  }
  .button-wrapper {
    width: 25%;
    min-width: 50px;
    padding: 0 0.5% 1%;
    ${theme.above.lg} {
      width: 20%;
    }
    ${theme.above.lg} {
      width: 16.666667%;
    }
  }
`;

export const VariantHeading = styled('h4')`
  margin-bottom: 0.3rem;
  font-weight: ${theme.weights.bold} !important;
  .size-button {
    font-weight: ${theme.weights.normal} !important;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 0.5rem;
  }
`;

const VariantWrapper = styled('div')`
  width: 100%;
`;

const VariantButton = ({
  option,
  variantHandler,
  showValidationMessage,
  sizeGuideTitle,
}) => {
  const {
    getSelectedValue,
    validateSelection,
    selectValue,
    getVariantForSelection,
  } = variantHandler;
  const selectedValue = getSelectedValue(option);

  return (
    <VariantWrapper>
      <VariantHeading>
        <span>{option.name}</span>

        {sizeGuideTitle && (
          <span
            className="size-button"
            onClick={() => {
              scrollTo('tab-header-tab-page-sizeguidespecific', true);
            }}
          >
            {sizeGuideTitle}
          </span>
        )}
      </VariantHeading>
      <VariantButtonWrapper className={showValidationMessage && 'invalid'}>
        {option.values.map((value, index) => {
          const validation = validateSelection(value, option);
          const item = getVariantForSelection(value, option);
          return (
            <div className="button-wrapper" key={index}>
              <StyledButton
                onClick={() => selectValue(value, option)}
                data-disabled={validation === 'invalid'}
                data-secondary={value !== selectedValue}
                data-selected={value === selectedValue}
                className={`${
                  item && item.stockStatus && !item.stockStatus.buyable
                    ? 'not-buyable '
                    : ''
                }`}
              >
                <span>{value}</span>
              </StyledButton>
            </div>
          );
        })}
      </VariantButtonWrapper>
    </VariantWrapper>
  );
};

export default VariantButton;
