import { useProductList } from '@jetshop/core/hooks/ProductList';
import React from 'react';
import { Link } from 'react-router-dom';
import FavouriteIcon from '../ui/icons/FavouriteIcon';
import { NumberBadge } from '../ui/NumberBadge';
import { config } from '../../shop.config.custom';
import t from '@jetshop/intl';

export function FavouriteCount({ className, listId, title = false }) {
  const { count } = useProductList(listId);
  return (
    <Link to={config.routes.favourites.path} className={className}>
      <span className="icon-wrapper">
        {count > 0 && <NumberBadge text={count} />}
        <FavouriteIcon className="header-icon" />
      </span>
      {title && <p>{t('Favourites')}</p>}
    </Link>
  );
}
