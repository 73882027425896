import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { ButtonUI } from '../../ui/ButtonUI';
import ProductLink from '@jetshop/ui/ProductLink';
import Image from '@jetshop/ui/Image';
import Badges from '@jetshop/ui/ProductList/Badges';
import { theme } from '../../Theming/Theming';
import { GridPrice } from '../../Price';
import { VariantSelector } from '../VariantSelector/VariantSelector';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import { useIntl } from '@jetshop/intl';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import StockStatusIndicator from '../StockStatus/StockStatusIndicator';
import { PercentPrice } from '../Price/PercentPrice';
import { Name, SubName } from '../../CategoryPage/ProductCard';
import { transparentDataImg } from '@jetshop/ui/Image/Image';

const ProductWrapper = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  background: white;
  border-top: 1px solid ${theme.colors.border};
  &:last-child {
    border-bottom: 1px solid ${theme.colors.border};
  }
  padding: 1rem 0;
  .upsell-product-image {
    .image-wrapper {
      position: relative;
    }
    width: 30%;
    ${theme.only.md} {
      width: 30%;
    }
    ${theme.above.lg} {
      width: 30%;
    }
    .badge-wrapper {
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
  .upsell-product-detail {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    ${theme.only.md} {
      width: 70%;
    }
    ${theme.above.lg} {
      width: 70%;
    }
    .upsell-product-header {
      width: 100%;
      padding: 0 1rem;
      ${theme.only.md} {
        width: 70%;
      }
      ${theme.above.xl} {
        width: 70%;
      }
      .name-tag {
        height: auto;
      }
      .sub-name {
        height: auto;
      }
      .min-quantity {
        font-weight: ${theme.weights.light};
        .multiplier {
          margin: 0 3px;
          text-transform: none;
        }
      }
      .upsell-stock-status {
        p {
          font-size: 0.9rem;
        }
      }
      .upsell-price-wrapper {
        margin-top: 5px;
        display: flex;
        align-items: center;
        [data-flight-price] {
          .new-price {
            margin-bottom: 0;
          }
        }
        .percent-price {
          margin-left: 1.2rem;
          z-index: 2;
          font-size: 0.9rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .upsell-product-cta {
      width: 100%;
      padding-left: 1rem;
      margin-top: 1rem;
      display: flex;
      justify-content: flex-end;
      ${theme.above.xl} {
        width: 30%;
      }
    }
  }
  .variant-wrapper {
    margin-top: 1rem;
    header > h4 {
      display: none;
    }
    [data-flight-dropdown-items] {
      position: absolute;
    }
  }
`;

const AddToButton = styled(ButtonUI)`
  font-weight: ${theme.weights.normal};
  font-size: 0.9rem;
  width: 100%;
  &[disabled] {
    font-size: 0.7rem;
  }
`;

export const UpsellProduct = ({ item, update }) => {
  const { product, minQuantity, upsellPrice } = item;

  //HAS IMAGES
  const hasImages = product.images && product.images.length > 0;

  //GET VARIANTS HANDLING
  const variantHandler = useProductVariants(product, {
    preselectCheapest: false,
  });

  //ACTIVATE VALIDATION WHEN CLICK BUTTON
  const [showValidation, setValidation] = useState(false);

  //VARIANT HANDLER
  const { selectedVariant, validation, missingOptions } = variantHandler;

  //STOCK STATUS
  const stockStatus = useStockStatus(selectedVariant || product);

  //ADD SELECTED VARIANT
  const purchaseItem = {
    ...item,
    variant: selectedVariant,
  };

  //IS PRODUCT SELECTED
  const selected = update.selected(purchaseItem);

  //VALIDATION MESSAGE ON VARIANTS
  const t = useIntl();
  const validationMessage =
    missingOptions?.length > 0
      ? t(`Select { option }`, {
          option: missingOptions[0].name,
        })
      : validation !== 'valid'
      ? stockStatus.text
      : false;

  //HANDLER WHEN CLICK ADD
  function eventHandler() {
    setValidation(true);
    if (!validationMessage) {
      if (!selected) update.add(purchaseItem);
      else update.remove(purchaseItem);
    }
  }

  //UPDATE WHEN CHANGING VARIANT
  useEffect(() => {
    if (!selected || !selectedVariant) return;

    if (selectedVariant?.articleNumber !== selected?.variant?.articleNumber) {
      if (selectedVariant.stockStatus.buyable) update.add(purchaseItem);
      else update.remove(purchaseItem);
    }
  });

  //STANDARD PRODUCT IMAGE
  const images =
    (selectedVariant?.images?.length > 0 && selectedVariant.images) ||
    (product && product.images);

  return (
    <ProductWrapper product={product} categoryPath={null}>
      <div className="upsell-product-image">
        <ProductLink product={product}>
          {hasImages ? (
            <div className="image-wrapper">
              <Image
                sizes={[200]}
                aspect={'1:1'}
                alt={images[0].alt}
                src={images[0].url}
              />
              <div className="badge-wrapper">
                <Badges badges={product.badges} />
              </div>
            </div>
          ) : (
            <Image src={transparentDataImg} />
          )}
        </ProductLink>
      </div>
      <div className="upsell-product-detail">
        <header className="upsell-product-header">
          <ProductLink product={product}>
            <Name className="name-tag">
              {minQuantity > 1 && (
                <span className="min-quantity">
                  {minQuantity}
                  <span className="multiplier">x</span>
                </span>
              )}
              {product.name}
            </Name>
          </ProductLink>
          <SubName className="sub-name">{product.subName}</SubName>
          <p className="short-description">{product.shortDescription}</p>
          <div className="upsell-stock-status">
            <StockStatusIndicator
              status={stockStatus.status}
              text={stockStatus.text}
            />
          </div>
          <div className="upsell-price-wrapper">
            <GridPrice {...upsellPrice} />
            <PercentPrice {...upsellPrice} />
          </div>
          {product.hasVariants && (
            <div className="variant-wrapper">
              <VariantSelector
                product={product}
                variantHandler={variantHandler}
                showValidation={showValidation && validationMessage}
              />
            </div>
          )}
        </header>
        <div className="upsell-product-cta">
          {product.stockStatus.buyable && (
            <AddToButton
              css={{ marginTop: '1rem' }}
              className={`${selected ? 'secondary ' : 'buy '}`}
              onClick={eventHandler}
              disabled={validation === 'outOfStock'}
            >
              {(showValidation && !!validationMessage) ||
              validation === 'outOfStock'
                ? validationMessage
                : selected
                ? t('Added')
                : t('Add')}
            </AddToButton>
          )}
        </div>
      </div>
    </ProductWrapper>
  );
};
