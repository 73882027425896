import React, { useContext } from 'react';
import { theme } from '../Theming/Theming';
import { styled } from 'linaria/react';
import SiteContentContext from '../SiteContent/SiteContentContext';

const ProductUspBoxWrapper = styled('div')`
  background: ${theme.colors.greys[1]};
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  ${theme.below.lg} {
    padding-bottom: 0;
  }
  .title {
    font-weight: ${theme.weights.bold};
    margin-bottom: 1rem;
  }
  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    ${theme.above.xl} {
      justify-content: space-around;
      flex-wrap: nowrap;
    }
    li {
      display: flex;
      align-items: center;
      margin: 0 1rem 1rem;
      font-size: 0.9rem;
      ${theme.above.xl} {
        margin: 0;
      }
      img {
        max-width: 22px;
        margin-right: 5px;
      }
    }
  }
`;

const ProductUspBox = () => {
  const { productPageUsp } = useContext(SiteContentContext);
  return <ProductUspBoxWrapper>{productPageUsp}</ProductUspBoxWrapper>;
};

export default ProductUspBox;
