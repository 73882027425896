import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';

const HighlightsWrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2rem 0;
  ${theme.below.sm} {
    padding: 1rem 0;
    flex-wrap: wrap;
  }
  > h3 {
    width: 40%;
    ${theme.below.sm} {
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;
    }
    font-weight: ${theme.weights.bold};
  }
  > div {
    width: 60%;
    ${theme.below.lg} {
      width: 100%;
    }
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      > img {
        height: 40px;
      }
      > h5 {
        text-transform: uppercase;
        margin-top: 5px;
        font-weight: ${theme.weights.bold};
      }
    }
  }
`;

const Highlights = ({ data }) => {
  const features = data.filter((item) => item.key === 'features')[0];

  if (!features) return null;

  return (
    <HighlightsWrapper>
      <h3>{features.title}</h3>
      <div className="icons">
        {features.listValues.map((item, index) => (
          <div key={index}>
            <img
              src={`https://www.minfot.se/pub_images/original/${item}.png`}
              alt={item}
            />
            <h5>{item}</h5>
          </div>
        ))}
      </div>
    </HighlightsWrapper>
  );
};

export default Highlights;
