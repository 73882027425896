import React from 'react';
import { styled } from 'linaria/react';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { useIntl } from '@jetshop/intl';

export const Input = styled('input')`
  background: #fff;
  border: none;
  box-shadow: 0;
  height: 50px;
  padding: 0 1rem;
  width: 100%;
  font-size: 16px;
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`;

const SearchInput = ({ getInputProps }) => {
  const t = useIntl();
  return (
    <FlyoutTrigger id="searchFlyout" showCover={false}>
      {({ showTarget }) => {
        return (
          <Input
            data-flight-search-autocomplete-field=""
            type="search"
            placeholder={t('Hi, what are you looking for?')}
            {...getInputProps({
              onFocus: showTarget,
            })}
          />
        );
      }}
    </FlyoutTrigger>
  );
};

export default SearchInput;
