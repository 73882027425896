import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { styled } from 'linaria/react';
import React from 'react';
import { GridPrice } from '../Price';
import { Favourite } from '../ProductList/Favourite';
import { ProductName } from '../ProductPage/ProductDetails';
import { theme } from '../Theming/Theming';
import { imageOverlay } from '../ui/General';

export const ProductCardInner = styled('div')`
  padding: ${theme.productGrid.product.padding.sm};
  background-color: ${theme.productGrid.product.background};
  border: ${theme.productGrid.product.border};
  position: relative;
  line-height: 1;
  min-width: 0;
  ${theme.above.md} {
    padding: ${theme.productGrid.product.padding.md};
  }
  ${theme.above.xl} {
    padding: ${theme.productGrid.product.padding.lg};
  }
  > a {
    color: inherit;
    display: block;
  }
  > button {
    width: 100%;
  }
  .favo-wrapper {
    position: relative;
  }
  .sibling-wrapper-card {
    margin-top: 8px;
    height: 16px;
  }
`;

export const Details = styled('div')`
  margin: 1rem 0 0.5rem;
  position: relative;
`;

export const DetailsInner = styled('div')`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-end;
  > * {
    flex-grow: 0;
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  ${imageOverlay}
  padding-bottom:30px;
`;

export const BadgeWrapper = styled('div')`
  color: white;
  text-align: center;
  font-size: 0.9rem;
  position: absolute;
  bottom: 3px;
  left: 3px;
  > div {
    > div {
      position: static;
      display: flex;
      > div {
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  img {
    width: auto;
    height: auto;
    max-width: 70px;
    ${theme.below.md} {
      max-width: 50px;
    }
  }
`;

export const Name = styled('h4')`
  margin: 0 0 8px 0 !important;
  padding: 0;
  padding-right: 1.8rem;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1.5;
  text-transform: none;
  font-family: ${theme.productGrid.product.name.fontFamily} !important;
  font-weight: ${theme.productGrid.product.name.fontWeight} !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const SubName = styled('h5')`
  margin: 0 0 4px 0;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
  font-weight: ${theme.weights.bold} !important;
  letter-spacing: 2px !important;
  font-size: 0.9rem;
  ${theme.below.md} {
    font-size: 0.9rem;
  }
`;
export const ListPrice = styled(GridPrice)``;

const PackagePrice = styled('div')`
  font-size: 0.9rem;
  margin-top: 5px;
`;

const ProductCardComponent = ({
  product,
  imageAspect,
  imageSizes,
  children,
  showFavorite,
  loadImageEagerly,
  categoryPath,
  ...rest
}) => {
  //HAS IMAGES
  const hasImages = product.images && product.images.length > 0;
  const image = product.images && product.images[0];
  /*
    const initialImage = product.images && product.images[0];

  const [image, setImage] = useState(initialImage);
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();

  const { relatedColor } = getShortDescription({ product });

  useEffect(() => {
    if (inView && !showed) setShowed(true);
  }, [inView, showed]);
  */

  let badges = [...product.badges];

  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge',
    });

  return (
    <ProductCardInner className="product-card-inner">
      {showFavorite && (
        <div className="favo-wrapper">
          <Favourite
            product={product}
            style={{
              position: 'absolute',
              top: '15px',
              right: '15px',
            }}
          />
        </div>
      )}
      <ProductLink product={product} categoryPath={categoryPath} {...rest}>
        {hasImages ? (
          <ImageWrapper>
            <Image
              sizes={imageSizes}
              aspect={imageAspect}
              alt={image.alt}
              src={image.url}
              modifiedDate={image.modifiedDate}
              critical={loadImageEagerly}
            />
            <BadgeWrapper>
              <Badges badges={product.badges} />
            </BadgeWrapper>
          </ImageWrapper>
        ) : (
          <Image src={transparentDataImg} aspect={imageAspect} />
        )}
      </ProductLink>
      <Details className="product-card-detail">
        <DetailsInner>
          <header>
            <ProductLink product={product} categoryPath={categoryPath}>
              <Name>
                <ProductName name={product.name} brand={product.subName} />
              </Name>
            </ProductLink>
          </header>
          {product.isPackage && (
            <PackagePrice className="package-price-label">
              {t('Package Price')}
            </PackagePrice>
          )}
          <ListPrice
            price={product.price}
            previousPrice={product.previousPrice}
          />
          {/* Hidden for perfomance 
          <div
            className="sibling-wrapper-card"
            onMouseLeave={() => setImage(initialImage)}
            ref={ref}
          >
            <SiblingProducts
              {...{
                product,
                id: relatedColor,
                grid: true,
                setImage,
                inView: showed,
              }}
            />
          </div>*/}
        </DetailsInner>
      </Details>
      {children}
    </ProductCardInner>
  );
};

export function ProductCard({
  product,
  className,
  imageAspect = theme.productGrid.product.imageAspect,
  imageSizes = [1 / 2, 1 / 2, 1 / 2.25, 1 / 3.25, 1 / 6],
  forwardRef,
  as = 'div',
  children,
  showFavorite = true,
  showVariants = true,
  loadImageEagerly = false,
  ...linkProps
}) {
  const Tag = as;

  //PROPS TO PASS TO PRODUCTCARD COMP
  const props = {
    imageAspect,
    imageSizes,
    children,
    showFavorite,
    loadImageEagerly,
    ...linkProps,
  };
  return (
    <Tag
      className={`product-card ${className}`}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductCardComponent product={product} {...props} />
    </Tag>
  );
}
