import React, { useContext } from 'react';
import Image from '@jetshop/ui/Image';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const Logo = ({ secondary = false, size = [400] }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const name = selectedChannel.id === 2 ? 'myfeet-logo' : 'minfot-logo';
  let src = `/pub_images/original/${name}.png`;
  if (secondary) src = `/pub_images/original/${name}_white.png`;
  return <Image src={src} sizes={size} aspect={'300:100'} crop={false} />;
};

export default Logo;
