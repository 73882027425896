import ChannelContext from '@jetshop/core/components/ChannelContext';
import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import loadable from '@loadable/component';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import loadFontCss from '../fonts/loadFontCss';
import { config, getChannel } from '../shop.config.custom';
import ForgotPassword from './Auth/ForgotPassword.loadable';
import LogInPage from './Auth/LogInPage.loadable';
import ResetPassword from './Auth/ResetPassword.loadable';
import SignUpPage from './Auth/Signup/SignUpPage.loadable';
import CategoryPage from './CategoryPage/CategoryPage.loadable';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import ContentPage from './ContentPage/ContentPage.loadable';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import MyPages from './MyPages/MyPages.loadable';
import NotFoundPage from './NotFoundPage.loadable';
import PagePreviewQuery from './PagePreviewQuery.gql';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import ProductPage from './ProductPage/ProductPage.loadable';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import SearchPage from './SearchPage/SearchPage.loadable';
import SiteContent from './SiteContent/SiteContentProvider';
import StartPage from './StartPage/StartPage.loadable';
import './Theming/globalStyles';
import { theme } from './Theming/Theming';
import useScript from './ui/hooks/useScript';
import CookieConsent from './Util/CookieConsent';
import CustomFont from './Util/Fonts/CustomFont';
import { KlaviyoTracking } from './Util/KlaviyoTracking';

const LogOutPage = loadable(
  () => import('@jetshop/core/components/AuthContext/LogOut'),
  {
    fallback: <LoadingPage />,
  }
);
const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />,
  }
);

const Routes = () => {
  const { routes } = useShopConfig();
  return (
    <Switch>

      <Route exact path="/">
        <StartPage />
      </Route>
      <Route path={config.routes.favourites.path}>
        <Favourites />
      </Route>
      <Route path={routes.search.path}>
        <SearchPage />
      </Route>
      <Route path={routes.signup.path}>
        <SignUpPage />
      </Route>
      <Route path={routes.login.path}>
        <LogInPage />
      </Route>
      <Route path={routes.logout.path}>
        <LogOutPage />
      </Route>
      <Route path={routes.myPages.path}>
        <MyPages />
      </Route>
      <Route exact path={routes.forgotPassword.path}>
        <ForgotPassword />
      </Route>
      <Route path={`${routes.resetPassword.path}/:token`}>
        <ResetPassword />
      </Route>
      <Route
        path="/preview"
        render={(props) => (
          <PreviewRoute
            productQuery={ProductPreviewQuery}
            categoryQuery={CategoryPreviewQuery}
            pageQuery={PagePreviewQuery}
            productPage={ProductPage}
            categoryPage={CategoryPage}
            contentPage={ContentPage}
            StartPage={StartPage}
            {...props}
          />
        )}
      />
      <DynamicRoute
        routeQuery={routeQuery}
        productPage={ProductPage}
        categoryPage={CategoryPage}
        contentPage={ContentPage}
        notFoundPage={NotFoundPage}
        LoadingPage={LoadingPage}
      />
    </Switch>
  );
};

function Shop() {
  const { selectedChannel } = useContext(ChannelContext);

  //TESTFREAKS REVIEWS
  useScript(
    `https://js.testfreaks.com/onpage/${
      getChannel(selectedChannel.id).domain
    }/head.js?v=2`,
    true
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!window.testFreaks) window.testFreaks = [];
      window.testFreaks.push(['load', ['seller']]);
    }
  });

  return (
    <GenericError>
      <ModalProvider>
        <KlaviyoTracking>
          <SiteContent>
            <Container>
              <LoadingBar />
              <CustomFont
                primaryFont={theme.font.primary}
                secondaryFont={theme.font.secondary}
                injectCss={loadFontCss}
              />
              <Helmet
                titleTemplate={`%s`}
                defaultTitle={getChannel(selectedChannel.id).name}
              />
              <ProductListProvider queries={productListQueries}>
                <PaginationProvider defaultProductsPerPage={36}>
                  <Header />
                  <Content>
                    <Routes />
                  </Content>
                  <Footer />
                </PaginationProvider>
              </ProductListProvider>
              <ModalRoot />
              <ScrollRestorationHandler
                ignoreForRouteTypes={[
                  'sortOrderChange',
                  'filterChange',
                  'paginationChange',
                ]}
              />
              <CookieConsent />
            </Container>
          </SiteContent>
        </KlaviyoTracking>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
