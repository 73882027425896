import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import NewsletterBox from './Newsletter/NewsletterBox';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import TryggEhandel from './TryggEhandel';

const FooterWrapper = styled('footer')`
  background: ${theme.footer.background};
  z-index: 2;
  a,
  a:active {
    text-decoration: underline;
    color: ${theme.footer.color};
  }
  .footer-upper-wrapper {
    padding: 50px 0 0;
    a {
    }
  }
  .footer-content {
    padding: 3rem 0 0;
    color: ${theme.footer.color};
  }
  .footer-middle {
    padding: 2rem 1rem;
    background: ${theme.colors.backgroundLight};
    display: flex;
    align-items: center;
    justify-content: center;
    .teh-certificate {
      img {
        max-width: 70px;
      }
    }
    img {
      margin: 0 auto;
      max-width: 500px;
      width: 90%;
    }
  }
  .footer-bottom {
    background: ${theme.colors.primaryDark};
    padding: 2rem 1rem;
    > div {
      margin: 0 auto;
      max-width: 800px;
      text-align: center;
      h1,
      h2,
      h3,
      h4 {
        margin-bottom: 1rem;
      }
    }
  }
`;

export const FooterContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 1rem;
  padding-top: 0.5rem;
  margin-bottom: 3rem;
  ${theme.below.sm} {
    flex-direction: column;
  }
  a {
    text-decoration: underline;
  }
  > div {
    width: 100%;
    padding: 2rem 0;
    ${theme.only.md} {
      width: 40%;
      text-align: center;
    }
    ${theme.above.lg} {
      width: 20%;
      padding: 3rem 1rem;
    }
    > h3 {
      margin-bottom: 1em !important;
    }
    > ul {
      > li {
        line-height: 2em;
      }
    }
    p {
      line-height: 1.7em;
      margin-bottom: 1rem;
    }
  }
`;

export const FooterLogo = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  background: ${theme.footer.logoWrapper.background};
  > div {
    width: 100%;
    max-width: 400px;
  }
`;

const Footer = () => {
  const {
    footer1,
    footer2,
    footer3,
    footer4,
    footer5,
    footerMiddle,
    footerBottom,
  } = useContext(SiteContentContext);
  return (
    <>
      <FooterWrapper>
        <div className="newsletter-box">
          <NewsletterBox />
        </div>
        <div className="footer-content">
          <MaxWidth>
            <FooterContentWrapper>
              <div>{footer1}</div>
              <div>{footer2}</div>
              <div>{footer3}</div>
              <div>{footer4}</div>
              <div>{footer5}</div>
            </FooterContentWrapper>
          </MaxWidth>
        </div>
        <div className="footer-middle footer-content">
          <TryggEhandel />
          {footerMiddle}
        </div>
        <div className="footer-bottom footer-content">
          <div>{footerBottom}</div>
        </div>
      </FooterWrapper>
    </>
  );
};

export default Footer;
