import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import qs from 'qs';
import loadable from '@loadable/component';
import LoadingPage from '../LoadingPage';
import { config } from '../../shop.config.custom';
import { theme } from '../Theming/Theming';
import { Route } from 'react-router-dom';
import BlogRoute from '../Blog/BlogRoute';
import SiteContentContext from '../SiteContent/SiteContentContext';

export const LoadableStandardCategoryPage = loadable(
  () => import('./StandardCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableWindowedCategoryPage = loadable(
  () => import('./WindowedCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

const CategoryPage = (props) => {
  const location = useLocation();
  const { search } = location;
  const { blogs } = useContext(SiteContentContext);

  if (config.routes.category.hide) return null;

  const searchObject = qs.parse(search, { ignoreQueryPrefix: true });
  const standardPagination =
    searchObject.standardPagination === 'true' || theme.productGrid.pagination;

  // Check if the current route is a blog route
  const locationPathStart = location.pathname.substring(1).replace(/\/.*/, '');
  let isBlogRoute = false;
  let blogIndex = 0;

  blogs?.forEach((blog, index) => {
    const blogUrlStart = blog?.category?.value?.primaryRoute?.path.substring(1);

    console.log(blogUrlStart, ' - blogUrlStart');

    if (locationPathStart === blogUrlStart) {
      isBlogRoute = true;
      blogIndex = index;
      return false;
    }
  });

  if (isBlogRoute) {
    return <BlogRoute blog={blogs[blogIndex]} />;
  } else if (standardPagination) {
    return <LoadableStandardCategoryPage {...props} />;
  } else {
    return <LoadableWindowedCategoryPage {...props} />;
  }
};

export default CategoryPage;
