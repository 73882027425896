import React from 'react';
import { styled } from 'linaria/react';
import PlusIcon from '../../ui/icons/PlusIcon';
import MinusIcon from '../../ui/icons/MinusIcon';
import { theme } from '../../Theming/Theming';

export const QuantityControlWrapper = styled('div')`
  width: 100px;
  display: flex;
  height: 40px;
  border: 1px solid ${theme.colors.border};
`;

export const IconWrapper = styled('div')`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 0.8rem;
    color: #777;
    background: white;
    cursor: pointer;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 5px;
    &:hover {
      color: #000;
    }
  }
`;

export const QuantityInput = styled('input')`
  background: white;
  height: 100%;
  border: none;
  padding: 0;
  width: 70%;
  text-align: center;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }
`;

const QuantityControl = ({ quantity, setQuantity, field = false }) => {
  const handleClick = (plus) => {
    const value = plus ? 1 : -1;
    let new_value = quantity + value;
    if (new_value < 1) new_value = 1;
    if (!field) setQuantity(new_value);
    else setQuantity(field.name, new_value);
  };
  return (
    <QuantityControlWrapper className="quantity-control-wrapper">
      <QuantityInput
        disableValidation
        wrapperClassName="quantity-input-wrapper"
        min="1"
        type="number"
        name="quantity"
        label="Quantity"
        value={quantity}
        onChange={(e) => {
          if (!field) setQuantity(e.currentTarget.value);
        }}
        {...field}
      />
      <IconWrapper>
        <PlusIcon
          className="quantity-icon plus"
          onClick={() => {
            handleClick(true);
          }}
        />
        <MinusIcon
          className="quantity-icon minus"
          onClick={() => {
            handleClick(false);
          }}
        />
      </IconWrapper>
    </QuantityControlWrapper>
  );
};

export default QuantityControl;
