import React from 'react';
import { styled } from 'linaria/react';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems,
} from '@jetshop/ui/DropdownMenu';
import { ReactComponent as Caret } from '../../../svg/Caret.svg';
import Image from '@jetshop/ui/Image';
import { theme } from '../../Theming/Theming';
import { getFontSize } from '../../Theming/helpers';
import t from '@jetshop/intl';

const heightStyle = `
  height: 40px;
  ${theme.above.lg}{
    height: 46px;
  }
`;

export const DropdownWrapper = styled('div')`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  [data-flight-dropdown] {
    background: white;
    [data-flight-dropdown-button] {
      background: white;
      width: 100%;
      display: flex;
      align-items: center;
      ${heightStyle};
      border: 1px solid ${theme.colors.borderInput};
      border-radius: 0 !important;
      justify-content: space-between;
      svg {
        transition: all 200ms;
      }
      span {
        font-family: ${theme.button.fontFamily};
        font-weight: ${theme.button.fontWeight};
        text-transform: ${theme.button.textTransform};
        ${getFontSize(theme.button.ListFontSize)}
        letter-spacing: ${theme.button.letterSpacing};
        .select {
          text-transform: none;
        }
      }
    }
    &[data-flight-dropdown-open='true'] {
      [data-flight-dropdown-button] {
        border-bottom: 1px solid ${theme.colors.white};
        svg {
          transform: rotate(-180deg);
        }
      }
    }
    [data-flight-dropdown-items] {
      position: static;
      width: 100%;
      border: 1px solid ${theme.colors.borderInput};
      border-top: none;
      margin-top: -1px;
      background: ${theme.colors.white};
      z-index: 101;
    }
    [data-flight-dropdown-item] {
      ${heightStyle};
      display: flex;
      align-items: center;
      padding: 0 1rem;
      border-top: 1px solid ${theme.colors.borderLight};
      font-size: 0.87rem;
      &:hover {
        background: ${theme.colors.greys[0]};
        color: ${theme.colors.font};
      }
      &:focus,
      &:hover {
        outline: none;
      }
      &.selected {
        background: ${theme.colors.greys[1]};
        font-weight: ${theme.weights.bold};
        p {
          font-weight: ${theme.weights.bold};
        }
      }
      > div {
        display: flex;
        align-items: center;
      }
    }
  }
  &.invalid [data-flight-dropdown-button] {
    box-shadow: 0 0 10px rgba(195, 45, 45, 0.38);
  }
`;

export const VariantWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const VariantInfo = styled('div')`
  display: flex;
  align-items: center;
`;

export const VariantImage = styled('div')`
  width: 60px;
  margin-right: 15px;
`;

export const VariantName = styled('span')``;

export const VariantStock = styled('div')`
  color: ${theme.colors.outOfStock};
  &.buyable {
    color: ${theme.colors.inStock};
  }
`;

const VariantDropdown = ({
  option,
  variantHandler,
  showValidationMessage,
  showImages = true,
}) => {
  const {
    getSelectedValue,
    validateSelection,
    selectValue,
    getVariantForSelection,
  } = variantHandler;
  const selectedValue = getSelectedValue(option);
  return (
    <DropdownWrapper className={showValidationMessage && 'invalid'}>
      <DropdownMenu>
        <DropdownMenuButton id={`dropdown-option-${option.name}`}>
          <span>
            {selectedValue ? (
              selectedValue
            ) : (
              <span className="select">
                {t('select')} {option.name}
              </span>
            )}
          </span>
          <Caret />
        </DropdownMenuButton>
        <DropdownMenuItems>
          {option.values.map((value) => {
            const validation = validateSelection(value, option);
            const variant = getVariantForSelection(value, option);
            return (
              <DropdownMenuItem
                className={selectedValue === value && 'selected'}
                key={value + option.name}
                disabled={validation === 'invalid'}
                onSelect={({ setIsOpen }) => {
                  selectValue(value, option);
                  setIsOpen(false);
                }}
                style={{ opacity: validation === 'invalid' ? 0.5 : 1 }}
              >
                <VariantWrapper>
                  <VariantInfo>
                    {showImages &&
                      variant &&
                      variant.images &&
                      variant.images.length > 0 && (
                        <VariantImage>
                          <Image
                            src={variant.images[0].url}
                            sizes={[60]}
                            aspect="4:3"
                            crop={false}
                          />
                        </VariantImage>
                      )}
                    <VariantName>{value}</VariantName>
                  </VariantInfo>
                  <VariantStock
                    className={
                      variant && variant.stockStatus.buyable && 'buyable'
                    }
                  >
                    {variant && variant.stockStatus.text}
                  </VariantStock>
                </VariantWrapper>
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuItems>
      </DropdownMenu>
    </DropdownWrapper>
  );
};

export default VariantDropdown;
