import AddToCartFormik from '@jetshop/core/components/Mutation/AddToCartFormik';
import React, { useContext, useState } from 'react';
import addToCartMutation from '../../Cart/queries/addToCart.gql';
import CartQuery from '../../Cart/queries/cartQuery.gql';
import ProductToastWrapper from './ProductToast';
import { useNotification } from '@jetshop/core/components/Notifications';
import { KlaviyoTrackingContext } from '../../Util/KlaviyoTracking';
import ProductPopup from './ProductPopup';

const AddToCartForm = ({ children, product, variant, getMissingOptions }) => {
  const [addToCartTrigger, setAddToCartTrigger] = useState(false);
  const [trigger, dismiss] = useNotification();
  const { setAdded, latestAdded } = useContext(KlaviyoTrackingContext);

  const onAddToCartInit = ({ mutationId, quantity, price }) => {
    trigger(
      <ProductPopup
        selectedVariation={variant}
        product={product}
        quantity={quantity}
        price={price}
      />,
      {
        id: mutationId,
        type: 'popup',
        autoCloseAfter: 30000,
      }
    );
  };
  const onAddToCartSuccess = (data) => {
    if (latestAdded !== data) setAdded(data);
    setAddToCartTrigger(true);
    setAddToCartTrigger(false);
  };
  const onAddToCartError = () => {
    return ({ variant, mutationId, quantity, price, error }) => {
      dismiss(mutationId);

      trigger(
        <ProductToastWrapper
          selectedVariation={variant}
          product={product}
          quantity={quantity}
          price={price}
          error={error}
        />,
        {
          type: 'add-to-cart',
          autoCloseAfter: 2000,
        }
      );
    };
  };
  return (
    <AddToCartFormik
      onAddToCartInit={onAddToCartInit}
      onAddToCartError={onAddToCartError}
      onAddToCartSuccess={onAddToCartSuccess}
      cartQuery={CartQuery}
      cartMutation={addToCartMutation}
      product={product}
      variant={variant}
      getMissingOptions={getMissingOptions}
    >
      {() => children(addToCartTrigger)}
    </AddToCartFormik>
  );
};

export default AddToCartForm;
