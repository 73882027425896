import React from 'react';
import { styled } from 'linaria/react';
import Spinner from './ui/Spinner';

const LoadingPageWrapper = styled('div')`
  .spinner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    padding: 20% 2rem 1rem;
  }
`;
const LoadingPage = () => {
  return (
    <LoadingPageWrapper>
      <div className="spinner">
        <Spinner />
      </div>
    </LoadingPageWrapper>
  );
};

export default LoadingPage;
