import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { SearchField } from '../Search';
import CartButton from '../../../Cart/CartButton';
import { Inner, PagePadding } from '../../MaxWidth';
import TopNav from '../Util/TopNav';
import Logo from '../Logo';
import { theme } from '../../../Theming/Theming';
import { ButtonUILinkExt } from '../../../ui/ButtonUI';
import cartQuery from '../../../Cart/queries/cartQuery.gql';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';

export const headerTransitions = `
  transition: all 0.5s ease;
`;

const DesktopHeaderWrapper = styled('div')`
  height: ${theme.desktopHeader.height.lg + 'px'};
  ${headerTransitions}
  padding: 0 0;
  ${theme.only.md} {
    height: ${theme.desktopHeader.height.md + 'px'};
  }
  &[data-scrolling='true'] {
    height: ${theme.desktopHeader.height.scroll + 'px'};
    .header-icon {
      font-size: 24px;
    }
  }
  background: ${theme.desktopHeader.background};
  .page-padding,
  .inner {
    height: 100%;
  }
  .page-padding {
    padding-right: 0;
  }
  .inner {
    display: flex;
    align-items: center;
  }

  .top-nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    > li {
      padding: 0 0.6rem;
      display: flex;
      height: 100%;
      align-items: center;
      &:last-child {
        padding-right: 0 !important;
      }
    }
  }
  .header-icon {
    font-size: 28px;
    color: ${theme.desktopHeader.color};
  }
  .header-button {
    position: relative;
    display: flex;
  }
  &[data-scrolling='true'] {
    .header-icon {
      font-size: 20px;
    }
  }
`;

const StoreLogo = styled('div')`
  transition: height 0.4s ease;
  width: 160px;
  ${theme.above.xxl} {
    width: 180px;
  }
  transition: width 0.4s ease;
  //margin: 0 auto;
  ${theme.only.md} {
    max-height: ${theme.desktopHeader.height.md - 20 + 'px'};
  }
  text-align: center;
  text-decoration: none;
  color: inherit;
  a {
    text-decoration: none;
    color: inherit;
    > img,
    > div,
    > svg {
      transition: max-height 0.4s ease;
      max-width: 100%;
      max-height: ${theme.desktopHeader.height.lg - 20 + 'px'};
      ${theme.only.md} {
        max-height: ${theme.desktopHeader.height.md - 20 + 'px'};
      }
    }
  }
  &[data-scrolling='true'] {
    width: 120px;
    a {
      > img,
      > div,
      > svg,
      > .scroll-logo {
        max-height: ${theme.desktopHeader.height.scroll - 20 + 'px'};
      }
    }
    .scroll-logo {
    }
  }
`;

export const HeaderButton = styled('button')`
  display: flex;
`;

export const HeaderIconText = styled('span')`
  ${theme.below.md} {
    display: none;
  }
`;

const SearchFieldWrapper = styled('div')`
  height: 100%;
  .search-wrapper {
    height: 100%;
    background: #f1f1f1;
    width: calc(100vw - 600px);
    padding-right: 0;
    z-index: 1;
    .search-field {
    }
  }
  input {
    height: 100%;
    padding-left: 5rem;
    background: transparent;
  }
  button.close-search {
    display: none;
  }
  .submit-search {
    left: 1.5rem;
    right: auto;
    svg {
      color: ${theme.colors.primary};
      font-size: 2rem;
    }
  }
`;

const CheckoutButton = styled(ButtonUILinkExt)`
  height: 100%;
  padding: 0 4rem;
  font-size: 0.9rem;
  text-transform: none;
  text-decoration: underline !important;
  text-underline-offset: 2px;
  font-weight: ${theme.weights.normal};
  letter-spacing: 0.5px;
`;

const DesktopHeader = ({ scrolling, searchOpen, setSearch, children }) => {
  return (
    <DesktopHeaderWrapper data-scrolling={scrolling}>
      <PagePadding>
        <Inner className="inner">
          <StoreLogo data-scrolling={scrolling}>
            <Link to="/">
              <Logo type="desktop" />
            </Link>
          </StoreLogo>
          {children}
          <TopNav right>
            <SearchFieldWrapper data-scrolling={scrolling}>
              <SearchField
                onCancel={() => {
                  setSearch(false);
                }}
                setSearch={setSearch}
                searchOpen={searchOpen}
                scrolling={scrolling}
              />
            </SearchFieldWrapper>
            <CartButton className="header-button" />
            <CartProvider query={cartQuery}>
              {(result) => {
                const checkoutUrl = result.data?.cart?.externalCheckoutUrl;
                const url = checkoutUrl ? checkoutUrl : '';
                return (
                  <CheckoutButton href={url}>{t('To checkout')}</CheckoutButton>
                );
              }}
            </CartProvider>
          </TopNav>
        </Inner>
      </PagePadding>
    </DesktopHeaderWrapper>
  );
};

export default DesktopHeader;
