import React from 'react';
import useScript from '../../ui/hooks/useScript';

const TryggEhandel = () => {
  useScript(
    'https://cert.tryggehandel.net/js/script.js?id=b13f3b28-978b-44a4-a6b8-8a0bebaf1a65'
  );

  return (
    <>
      <div className="teh-certificate" data-size="150" />
    </>
  );
};

export default TryggEhandel;
