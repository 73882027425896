import React from 'react';
import { styled } from 'linaria/react';
import { useContext, useState } from 'react';
import SiteContentContext from '../SiteContent/SiteContentContext';
import { convertPropsToObject } from '../ContentRender/ContentRender';
import RightIcon from '../ui/icons/RightIcon';
import TextContent from '../ui/TextContent';
import { theme } from '../Theming/Theming';
import { StructuredData } from '@jetshop/core/components/StructuredData';

const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding-top: 4rem;
  h3 {
    text-align: center;
    margin-bottom: 1rem;
  }
  .question-list {
    .question-wrapper {
      border-top: 1px solid ${theme.colors.borderLight};
      &:last-child {
        border-bottom: 1px solid ${theme.colors.borderLight};
      }
      padding: 1rem 0;
      .question {
        position: relative;
        button {
          text-align: left;
          width: 100%;
          > span {
            padding-right: 10px;
            display: inline-block;
          }
          svg {
            position: absolute;
            top: calc(50% - 8px);
            right: 0;
            &.open {
              transform: rotate(90deg);
            }
          }
        }
      }
      .answer {
        margin-top: 1rem;
        display: none;
        &.open {
          display: block;
        }
      }
    }
  }
`;

const Question = ({ data }) => {
  const [drop, setDrop] = useState(false);
  const { question, answer } = data;
  return (
    <div className="question-wrapper">
      <div className="question">
        <h4>
          <button onClick={() => setDrop(!drop)}>
            <span>{question}</span>
            <RightIcon className={`${drop ? 'open' : ''}`} />
          </button>
        </h4>
      </div>
      <div className={`answer ${drop ? 'open' : ''}`}>
        <TextContent content={answer} />
      </div>
    </div>
  );
};



const Faq = ({ heading, items }) => {
  let mainEntity = [];
  const FaqList = items.map((item) => {
    const props = convertPropsToObject(item);
    const question = props.question.value;
    const answer = props.answer.value;
    mainEntity.push({
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
    });
    return {
      question,
      answer,
    };
  });

  return (
    <Wrapper>
      <StructuredData
        json={{
          '@context': 'https://schema.org/',
          '@type': 'FAQPage',
          mainEntity: mainEntity,
        }}
      />
      <h3>{heading}</h3>
      <div className="question-list">
        {FaqList.map((item, index) => (
          <Question data={item} key={index} />
        ))}
      </div>
    </Wrapper>
  );

}





// CUSTOM FAQ ELEMENT FOR USE ANYWHERE
export const CustomFaq = ({ data  }) => {
  const { categoryBottomFaqTitle } = useContext(SiteContentContext);
  const customHeading = data?.properties?.filter((item) => item.name === 'heading')[0]?.value?.value;
  const isCustomFaq = data?.properties?.filter((item) => item.name === 'isCustomFaq')[0]?.value?.value;
  return isCustomFaq ?  (
    <Faq heading={customHeading ?? categoryBottomFaqTitle} items={data.children} />
  ) : null;
}



// SEO FAQ FOR BOTTOM OF CATEGORY PAGE
const SeoFaq = ({ category }) => {
  const data = category?.data?.items.filter((item) => item.type === 'faq')[0];
  const { categoryBottomFaqTitle } = useContext(SiteContentContext);
  if (!data) return null;
  const customHeading = data?.properties?.filter((item) => item.name === 'heading')[0]?.value?.value;
  const isCustomFaq = data?.properties?.filter((item) => item.name === 'isCustomFaq')[0]?.value?.value;
  return isCustomFaq ? null : (
    <Faq heading={customHeading ?? categoryBottomFaqTitle} items={data.children} />
  );
};

export default SeoFaq;
